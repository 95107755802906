(async function () {
    try {
      const res = await fetch("data/app.json");
      if (res.ok){
        const json = await res.json();
        console.info(`${json.name}, ${json.version}`);
      } else {
        throw new Error("Could not retrieve app.json");
      }
    } catch (e) {
      console.error("Error fetching application meta data: ", e);
    }
})();
